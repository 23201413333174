import React from 'react'
import Container from '@material-ui/core/Container'

export default function CenterWrapper(props) {
  return (
    <Container className={props.style} maxWidth='lg'>
      {props.children}
    </Container>
  )
}
