import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { View, Button, CenterWrapper } from 'lib'
import { Helmet } from 'react-helmet'
import { AppBar, Toolbar, Hidden, Slide, SwipeableDrawer, useScrollTrigger, MenuItem, IconButton, Menu } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/styles'
import { navigate, Link } from 'gatsby'
import { FaBars } from 'react-icons/fa'
import logo from '../../../static/images/logo.png'
import logoBusiness from '../../../static/images/logoBusiness.png'
import '_appSetup/Global.css'
import firebase from 'gatsby-plugin-firebase'
import { useUserData } from 'pages/dashboard/_utils'

import { signOut } from 'actions/User'

const ProfileAndLogOut = ({ emptyNavbar, openedNotLoggedInPath }) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label='account of current user'
        aria-controls='primary-search-account-menu'
        aria-haspopup='true'
        color='secondary'
        disableRipple
        onClick={handleMenu}
      >
        <AccountCircle/>
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {!emptyNavbar && !openedNotLoggedInPath && (
          <View>
            <Link to={'/profile'} className={styles.link}><MenuItem>Account settings</MenuItem></Link>
            <Link to={'/tax'} className={styles.link}><MenuItem>Tax information</MenuItem></Link>
            <Link to={'/support'} className={styles.link}><MenuItem>Help & support</MenuItem></Link>
          </View>
        ) }
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    </>
  )
}

function getButtons(path = '#') {
  const buttons = []

  path = path.split('/').filter(e => e.length)
  const activeSubpage = path[0]
  const openedNotLoggedInPath = !activeSubpage || ['start', 'login', 'signup', 'closed'].includes(activeSubpage)

  if (!openedNotLoggedInPath) {
    buttons.push(...[
      { text: 'Home', to: '/dashboard', active: activeSubpage == 'dashboard' },
      { text: 'Withdraw UST', to: '/withdraw', active: activeSubpage == 'withdraw' },
      { text: 'Claim LUNA airdrop', to: '/withdrawLuna', active: activeSubpage == 'withdrawLuna' },
    ])
  } else {
    if (path.includes('signup')) {
      buttons.push(...[
        { text: 'Sign In', to: '/login/' },
      ])
    }
  }
  return { buttons, openedNotLoggedInPath }
}

function NavButtons({ path, emptyNavbar }) {
  const { buttons, openedNotLoggedInPath } = getButtons(path)
  const isSignedIn = firebase.auth().currentUser?.emailVerified
  return (
    <>
      {!emptyNavbar && buttons.map((props, idx) => {
        return (
          <NavButton key={idx} {...props}/>
        )
      })}
      {isSignedIn && <ProfileAndLogOut emptyNavbar={emptyNavbar} openedNotLoggedInPath={openedNotLoggedInPath}/>}
    </>
  )
}

function MobileDropdown({ path, emptyNavbar, ...props }) {
  const { buttons } = getButtons(path)
  const [state, setState] = useState({ isOpen: false })
  const isSignedIn = firebase.auth().currentUser?.emailVerified
  const styles = useStyles(props)

  return (
    <View>
      <Button onClick={() => setState({ isOpen: !state.isOpen })} className={styles.hamburger}>
        <FaBars size={30} color='#1A3066'/>
      </Button>
      <SwipeableDrawer
        anchor='right'
        open={state.isOpen}
        onOpen={() => setState({ isOpen: true })}
        onClose={() => setState({ isOpen: false })}
        classes={{ paper: styles.toolbar }}
      >
        {!emptyNavbar && buttons.map((props, idx) => { return <NavButton key={idx} {...props} mobile/> })}
        {isSignedIn && <ProfileAndLogOut emptyNavbar={emptyNavbar}/>}
      </SwipeableDrawer>
    </View>
  )
}

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

function NavButton(props) {
  const { text, to, mobile } = props
  const styles = useStyles(props)
  return (
    <View style={[mobile && styles.mobileNavButton]}>
      <Button internalLink to={to} size='medium' color='secondary' text={text} style={props.active && styles.underline}/>
    </View>
  )
}

export default function HeaderNavBar(props) {
  const styles = useStyles(props)
  const { pathname } = useLocation()
  const isSignedIn = typeof window !== 'undefined' && firebase.auth().currentUser?.emailVerified
  const logoHref = isSignedIn ? '#' : 'https://stablegains.com'

  const { UserData } = useUserData()
  const isBusiness = UserData?.accountType == 'BUSINESS'
  const logoImage = isBusiness ? logoBusiness : logo

  const goHome = () => {
    if (isSignedIn && !pathname.includes('verify')) navigate('/dashboard')
  }
  return (
    <View>
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar classes={{ root: styles.toolbar }}>
            <CenterWrapper style={styles.centerWrapper}>
              <a href={logoHref} onClick={goHome} className={styles.logoWrapper}>
                <View>
                  <img style={{ height: isBusiness ? '28px' : '24px' }} src={logoImage} alt='logo'/>
                </View>
              </a>

              <Hidden xsDown>
                <View style={styles.navButtons}>
                  <NavButtons emptyNavbar={props.emptyNavbar} path={pathname}/>
                </View>
              </Hidden>
              <Hidden smUp>
                <View style={styles.navButtonsMobile}>
                  <Helmet>
                    <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=yes, viewport-fit=cover, maximum-scale=1.0, user-scalable=0'/>
                  </Helmet>
                  <MobileDropdown emptyNavbar={props.emptyNavbar} path={pathname}/>
                </View>
              </Hidden>

            </CenterWrapper>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </View>
  )
}

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    pointerEvents: 'none',
  },
  welcomeText: {
    margin: theme.spacing(2),
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  navButtonsMobile: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileNavButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  navButtonText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    borderColor: theme.primary,
    borderWidth: 2,
    borderRadius: theme.spacing(3),
  },
  centerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputRoot: {
    borderWidth: 1,
    borderRadius: theme.spacing(8),
    borderColor: 'rgba(175, 175, 175, 1)',
    borderStyle: 'solid',
    height: 36,
    paddingLeft: theme.spacing(2),
  },
  underline: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  link: {
    textDecoration: 'none',
  },
  hamburger: {
    marginRight: 0,
    marginLeft: 0,
    padding: '6px 0',
    minWidth: 0,
  },
  toolbar: {
    paddingTop: 'env(safe-area-inset-top)',
  },
}))
