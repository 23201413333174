import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

/**
 * <Input/>
 *
 * @prop {number} id element id
 * @prop {string} label display label
 * @prop {string} value current field value
 * @prop {object} style styles to be applied to text component
 * @prop {function} onChange what to do when shit changes
 */

export default function Input(rawProps) {
  const { id, style, value, label, onChange, error, ...props } = rawProps
  const localStyles = useStyles()
  const styles = style + ' ' + localStyles

  const handleChange = event => {
    onChange(event)
  }

  return (
    <TextField
      error={error}
      id={id}
      label={label}
      className={styles}
      value={value}
      onChange={handleChange}
      margin='normal'
      variant='outlined'
      {...props}
    />
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
}))

