import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { Link } from 'gatsby'

export default function UIButton(rawProps) {
  const { style, text, children, ...props } = rawProps
  const classNames = Array.isArray(style) ? style.join(' ') : style
  const content = children ? children : text


  const ButtonComponent = () => (
    <Button disableRipple className={classNames} color='primary' {...props} disabled={props.disabled || props.loading}>
      <>
        {props.loading ? <CircularProgress size={24} color={props.variant == 'contained' ? 'white' : undefined}/> : content}
      </>
    </Button>
  )

  return props.internalLink ? (
    <Link to={props.to} style={{ textDecoration: 'none' }}>
      <ButtonComponent/>
    </Link>
  ) : <ButtonComponent/>
}
