import React from 'react'
import { Page } from 'lib'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const WhiteGreyLayout = (props) => {
  const styles = useStyles()

  return (
    <Grid container className={styles.container} spacing={0}>
      <Grid item xs={12} sm={12} md={8} className={styles.leftContainer}>
        {props.leftComponent()}
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.rightContainer}>
        {props.rightComponent()}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    flex: 1,
    width: '100vw',
  },
  rightContainer: {
    backgroundColor: 'rgba(26, 48, 102, 0.02)',
    flex: 1,
  },
}))

export default WhiteGreyLayout
