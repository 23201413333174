import React from 'react'

import Modal from 'react-modal'
Modal.setAppElement('body')
const ModalComponent = (props) => {

  return (
    <Modal {...props}>
      {props.children}
    </Modal>
  )
}

export default ModalComponent
