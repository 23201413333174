/* eslint no-console: "off" */
/* eslint no-await-in-loop: "off" */

import { store } from 'reduxRoot'
import { Settings } from '_appSetup'

const FAKE_MODE = false

function appendSlash(url) {
  if (!url.includes('?') && url.charAt(url.length - 1) != '/') {
    url += '/'
  }
  return url
}

async function Fetch(params) {
  if (FAKE_MODE) {
    console.info('[FAKE Fetch]', params)
    params.successCallback(params.responseData)
    return
  }

  let options = {
    full_url: false,
    json: true,
    multipart: false,
    fake_db: false,
    formData: false,
  }

  if (params.hasOwnProperty('options')) {
    options = params.options
  }

  let AUTH_HEADER
  if (options.multipart) {
    AUTH_HEADER = {
      Accept: '*/*',
    }
  } else {
    AUTH_HEADER = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const AUTH_TOKEN = await store.getState().Session.token
  if (AUTH_TOKEN) {
    AUTH_HEADER.Authorization = AUTH_TOKEN
  }

  const url = options.fake_db
    ? appendSlash(Settings.FAKE_DB + params.url)
    : appendSlash(Settings.BASE_URL + params.url)

  const data =
    params.data &&
    (options.multipart ? params.data : JSON.stringify(params.data))

  const headers = params.headers ? params.headers : AUTH_HEADER

  const tempOptions = {
    method: params.method,
    headers: headers,
  }

  tempOptions.body = new FormData()
  for (const key in data) {
    tempOptions.body.append(key, data[key])
  }

  console.log('> > > FETCH > > > ')
  console.info(params.method, { url, params, headers, data })
  try {
    let response
    if (options.formData) {
      response = await fetch(url, tempOptions)
    } else {
      response = await fetch(url, {
        method: params.method,
        mode: params.mode,
        body: data,
        headers: headers,
      })
    }
    console.log('response', response)
    if (response && response.ok) {
      if (params.hasOwnProperty('successCallback')) {
        if (options.json) {
          try {
            const json = await response.json()
            params.successCallback(json)
          } catch (err) {
            console.warn('> > > > > > INTERNAL ERROR < < < < < <', { err })
            params.successCallback()
          }
        } else {
          params.successCallback()
        }
      }
    } else {
      console.warn('> > > > > > SERVER ERROR < < < < < <', {
        AUTH_HEADER,
        url,
        params,
        response,
      })
      if (params.hasOwnProperty('failureCallback')) {
        params.failureCallback(response)
      }
      return
    }
  } catch (err) {
    console.warn('> > > > > > NETWORK ERROR < < < < < <', {
      AUTH_HEADER,
      url,
      params,
      err,
    })
    if (params.hasOwnProperty('failureCallback')) {
      params.failureCallback('NETWORK_ERROR')
    }
  }
}

export default Fetch
