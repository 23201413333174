import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const UITable = ({ style, rows, ...props }) => {
  const styles = useStyles()

  return (
    <Table className={`${styles.table} ${style}`} aria-label='simple table'>
      {(!rows || !rows.length) && <caption>{props.noDataCaption}</caption>}
      {props.head && (
        <TableHead className={styles.head}>
          <TableRow>
            {props.head.map((c, i) => <TableCell className={styles.headCell} key={i}>{c}</TableCell>)}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i}>
            {row.map((c, j) => {
              return (
                <TableCell className={styles.cell} key={j}>{c}</TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: 'red',
  },
  headCell: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    opacity: 0.5,
    fontSize: 14,
  },
  cell: {
    color: theme.palette.primary.main,
  },
}))

export default UITable
