import React from 'react'
import { Page } from 'lib'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

const TileWrapper = ({ children, large = false }) => {
  const styles = useStyles()

  return (
    <Page center noPaddings>
      <Grid container spacing={0} className={styles.container}>
        <Grid item xs={12} sm={12} md={large ? 10 : 6} lg={ large ? 8 : 6} className={styles.innerContainer}>
          <Grid
            container
            spacing={2}
            direction='row'
            justify='center'>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    backgroundColor: `#EDF5FF`,
    backgroundSize: 'cover',
  },
  innerContainer: {
    display: 'flex',
    minHeight: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px #00000029',
    padding: '24px',
  },
}))

export default TileWrapper
