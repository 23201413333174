import firebase from 'gatsby-plugin-firebase'

const track = (event, properties) => {
  if (!window.analytics) return

  const userId = properties?.userId || firebase.auth().currentUser?.email

  window.analytics.track(event, {
    userId,
    ...properties,
  })
}

export default {
  track,
}
