import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Text(rawProps) {
  const { style, text, children, ...props } = rawProps
  return (
    <Typography className={style} {...props}>
      {children || text}
    </Typography>
  )
}
