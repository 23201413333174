/* eslint no-console: 'off' */
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export default function View(rawProps) {
  const { style, children, ...props } = rawProps
  const classNames = Array.isArray(style) ? style.join(' ') : style
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  )
}
