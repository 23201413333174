import React, { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const formatUSD = (value, removeDollarSign = false) => {
  const rounded = roundDownToDecimals(value, 2)
  let formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rounded)
  if (removeDollarSign) formatted = formatted.replace('$', '')
  return formatted
}

export const formatAPY = (value = 0.15) => {
  return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(value)
}

export const calculateInterest = ({ startingBalance, APY, days }) => {
  return (startingBalance * (((APY + 1.0) ** (days / 365)) - 1))
}

export const roundDownToDecimals = (value, decimal) => {
  const multiplier = 10 ** decimal
  return Math.floor(value * multiplier) / multiplier
}

export const calculateBalance = (UserData) => {
  if (!UserData || !Object.keys(UserData).length || UserData.balance == 0) return { currentBalance: 0, contributions: 0, APY: 0.15 }

  // there could be two or more changes on the same day, so first filtering out the earlier ones
  const interestChanges = {}
  Object.values(UserData.interestChanges).forEach(v => {
    interestChanges[v.date] = v
  })

  // set up the gauntlet and add transactions
  const allEvents = {}

  if (UserData.transactions) {
    const txs = Object.values(UserData.transactions).filter(t => t)
    txs.forEach(t => {
      if (!allEvents[t.date]) allEvents[t.date] = []
      allEvents[t.date].push(t)
    })
  }

  // referral bonuses
  if (UserData.bonusPayments) {
    Object.values(UserData.bonusPayments).forEach(p => {
      if (!allEvents[p.date]) allEvents[p.date] = []
      allEvents[p.date].push({ ...p, type: 'DEPOSIT' })
    })
  }

  // add interest changes
  for (const elem in interestChanges) {
    if (!allEvents[elem]) allEvents[elem] = []
    allEvents[elem].push({ ...interestChanges[elem], type: 'APY_CHANGE' })
  }

  let balance = 0
  let contributions = 0
  let APY = 0

  const allDates = Object.keys(allEvents).sort()

  // go through all events and calculate the balance now
  for (let i = 0; i < allDates.length; i++) {
    const currentDate = allDates[i]

    allEvents[currentDate].forEach(event => {
      if (event.type == 'DEPOSIT' && event.status != 'PENDING') { // should be day after we receive
        balance += event.amount
        contributions += event.amount
      } else if (event.type == 'WITHDRAWAL') { // should be on the day requested
        balance -= event.amount
        contributions -= event.amount
      } else if (event.type == 'APY_CHANGE') { // should be day after it is modified
        if (event.increment) {
          APY += event.increment
        } else if (event.newValue) {
          APY = event.newValue
        }
      }
    })

    let nextDate = allDates[i + 1] && moment(allDates[i + 1], 'YYYY-MM-DD')
    if (!nextDate) {
      if (moment(allDates[i], 'YYYY-MM-DD').isAfter(moment())) {
        nextDate = moment(allDates[i], 'YYYY-MM-DD').endOf('day')
      } else {
        nextDate = moment().endOf('day')
      }
    }

    const days = moment(nextDate).diff(moment(currentDate, 'YYYY-MM-DD'), 'days')

    if (balance > 0) balance += calculateInterest({ startingBalance: balance, APY, days })
  }

  return { currentBalance: balance, contributions, APY }
}

export const useUserData = () => {
  let UserData = useSelector(state => state.User)
  UserData = {
    balance: 0, // default
    APY: 0.15, // default
    ...UserData,
  }
  const { contributions } = calculateBalance(UserData)
  return { contributions, UserData }
}
