import React, { useEffect } from 'react'
import { View, HeaderNavBar, CenterWrapper, SEO } from 'lib'
import { makeStyles } from '@material-ui/styles'

export default function Page({ showNav = true, ...props }) {
  const styles = useStyles()
  const Wrapper = props.fullWidth ? React.Fragment : CenterWrapper

  return (
    <View style={styles.wrapper}>
      <SEO/>
      {showNav && <HeaderNavBar emptyNavbar={props.emptyNavbar}/> }
      <View style={[styles.innerWrapper, props.center && styles.center, props.noPaddings && styles.noPaddings]}>
        {props.children}
      </View>
    </View>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: '100vh',
    position: 'relative',
    paddingTop: 'env(safe-area-inset-top)',
  },
  innerWrapper: {
    paddingTop: theme.mixins.toolbar.minHeight,
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
  noPaddings: {
    paddingTop: theme.mixins.toolbar.minHeight,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
